import React, { useContext, IMG_URL } from "react";
import { useState, useEffect, useRef } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import JoditEditor from "jodit-react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../../common/Button";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
const axios = require("axios");
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, deleteData, IMG_URL } = useContext(Context);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const id = props.show;
  const [room, setRoom] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [bedOptions, setBedOptions] = useState([]);
  const [bed, setBed] = useState([]);
  const [selectedFacilityNames, setSelectedFacilityNames] = useState([]);
  const [errors, setErrors] = useState();
  const [formData, setFormData] = useState({
    name: "",
    size: "",
    bed_id: null,
    capacity: null,
    view: "",
    price: "",
    facilities: "",
    description: "",
    image: "",
    images: "",
    rating: "",
  });
  const [roomImages, setRoomImages] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleFacilityChange = (selectedFacilities) => {
    setFacilities(selectedFacilities);

    const facilityNames = selectedFacilities?.map((facility) => facility.label);
    setFormData({ ...formData, facilities: facilityNames });
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  async function getSelectedBed(id) {
    const response = await getData(`/allbed/${id}`);

    if (response.success) {
      setBed(response.data);
    }
  }

  const handleChange = (e) => {
    if (e?.name === "room_id") {
      setFormData({ ...formData, [e.name]: e });
      getSelectedBed(e.value);
    } else if (e?.name === "bed") {
      const selectedBedId = e?.value;
      const selectedBed = bedOptions.find((bed) => bed.value === selectedBedId);

      setFormData((prevFormData) => ({
        ...prevFormData,
        bed: selectedBed,
        bed_id: selectedBedId,
      }));
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d*\.?\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexNumeric = /^[0-9]+$/;
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Room name is required";
    }
    // else if (!regexAlfaNumric.test(formData.name?.trim())) {
    //   errors.name = "Enter a valid room name";
    // }

    if (!formData.image) {
      errors.image = "Image is required";
    }

    if (!formData.size) {
      errors.size = "WashRoom is required";
    } else if (!regexNumeric.test(formData.size?.trim())) {
      errors.size = "WashRoom must be a numeric value";
    }

    if (!formData.bed) {
      errors.bed = "Bed type is required";
    }

    if (!formData.capacity) {
      errors.capacity = "Capacity is required";
    } else if (!regexNumeric.test(formData?.capacity)) {
      errors.capacity = "Capacity must be a numeric value";
    }

    if (!formData.view) {
      errors.view = "View is required";
    }

    if (!formData.price) {
      errors.price = "Price is required";
    } else if (!regexNumeric.test(formData.price?.trim())) {
      errors.price = "Price must be a numeric value";
    }

    if (!facilities || facilities.length === 0) {
      errors.facilities = "Facilities are required";
    }

    if (!formData.description) {
      errors.description = "Description is required";
    }

    // Add more validations as needed

    return errors;
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append("name", formData.name);
        finalData.append("size", formData.size);
        finalData.append(
          "bed_id",
          formData.bed_id ? formData.bed_id : formData.bed.id
        );
        finalData.append("capacity", formData.capacity);
        finalData.append("view", formData.view);
        finalData.append("price", formData.price);
        finalData.append("image", formData.image);
        finalData.append("description", formData.description);
        finalData.append("facilities", JSON.stringify(facilities));
        finalData.append("rating", formData.rating);

        if (formData.images) {
          for (let i = 0; i < formData.images.length; i++) {
            finalData.append(`images[${i}]`, formData.images[i]);
          }
        }

        const response = await postData(`/masters/room/${id}`, finalData);
        if (response?.success) {
          setShowModal({ code: response?.code, message: response?.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response?.code, message: response?.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    try {
      const response = await getData(`/masters/room/${id}`);
      console.log(response);
      setDeleteImages(true);
      setFormData((prevData) => ({
        ...prevData,
        ...response?.data,
        // rating: response?.data?.rating || 5, // Set a default if rating is not presentz
      }));

      if (response?.data?.id) {
        fetchDataForSelectedroom(response.data.id);
        const selectedFacilities = response?.data?.roomfacilities?.map(
          (facility) => ({
            value: facility.id,
            label: facility.name,
          })
        );
        setFacilities(selectedFacilities);
      }
    } catch (error) {
      console.error("Error fetching Room data:", error);
    }
  };

  const fetchFacilityNames = async (facilityIds) => {
    try {
      const response = await getData(
        `/masters/facilities/${facilityIds?.join(",")}`
      );
      if (response?.data?.success) {
        const facilityNames = response?.data?.data?.map((facility) => ({
          value: facility?.id,
          label: facility?.name,
        }));
        setSelectedFacilityNames(facilityNames);
      }
    } catch (error) {
      console.error("Error fetching facility names:", error);
    }
  };

  const fetchDataForSelectedroom = async (room_id) => {
    try {
      const response = await getData(`/allroomimages/${room_id}`);
      if (response?.success) {
        setRoomImages(response?.data);
      }
    } catch (error) {
      console.error("Error fetching Room images:", error);
    }
  };

  const handleDeleteImage = async (id) => {
    try {
      const response = await deleteData(`/masters/room/deleteroomimage/${id}`);

      if (response?.data?.success) {
        setRoomImages((prevImages) =>
          prevImages.filter((image) => image.id !== id)
        );
        GetEditData();
        // setSubCategoryImages(updatedImages);
        GetEditData();
      } else {
        // Handle error response
        console.error("Error deleting Room image:", response?.data?.error);
      }
    } catch (error) {
      console.error("Error deleting Room image:", error.message);
    }
  };
  useEffect(() => {
    GetEditData();
  }, [0]);

  const fetchFacilities = async () => {
    try {
      const response = await getData("/masters/facilities");
      if (response?.success) {
        const facilitiesData = response.data.data;

        if (Array.isArray(facilitiesData)) {
          const options = facilitiesData?.map((facility) => ({
            value: facility?.id,
            label: facility?.name,
          }));
          setFacilityOptions(options);
        } else {
          console.error(
            "Invalid data format for facilities. Expected an array."
          );
        }
      }
    } catch (error) {
      console.error("Error fetching facilities:", error);
    }
  };

  const GetAllBeds = async () => {
    const response = await getData("/allbed");

    const option = [];
    if (response?.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          label: data.name,
        });
      });
    }
    setBedOptions(option);
  };

  useEffect(() => {
    fetchFacilities();
  }, []);
  useEffect(() => {
    GetAllBeds(); // Fetch bed options when the component mounts
  }, []);
  useEffect(() => {
    fetchFacilityNames(); // Fetch bed options when the component mounts
  }, []);

  const renderSelectedRoomImages = () => (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Image</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {roomImages?.map((image) => (
          <tr key={image.id}>
            <td>{image.id}</td>
            <td>
              <img
                src={IMG_URL + image.image}
                alt={IMG_URL + image.image}
                className="room-image"
                style={{ height: "75px", width: "110px" }}
              />
            </td>
            <td>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleDeleteImage(image.id)}
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  useEffect(() => {
    GetEditData();
  }, []);

  const GetAllroom = async () => {
    const response = await getData("/allroom");
    const option = [];
    if (response?.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          label: data.name,
        });
      });
    }
    setRoom(option);
  };

  useEffect(() => {
    GetAllroom();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Room</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="roomclass"
                          >
                            <Row>
                              <Col md={6}>
                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={5}>
                                      Room Images
                                    </Form.Label>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      <div className="selected-category-images">
                                        {renderSelectedRoomImages()}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Room
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData?.name}
                                            onChange={handleChange}
                                            placeholder="Room "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.name && (
                                          <span style={errorStyle}>
                                            {errors?.name}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      WashRoom
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="size"
                                            value={formData?.size}
                                            onChange={handleChange}
                                            placeholder="WashRoom "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.size && (
                                          <span style={errorStyle}>
                                            {errors?.size}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Bed
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <Select
                                          options={bedOptions}
                                          value={bedOptions.find(
                                            (bed) =>
                                              bed.value === formData.bed?.id
                                          )}
                                          onChange={(selectedBed) =>
                                            handleChange({
                                              name: "bed",
                                              value: selectedBed?.value,
                                            })
                                          }
                                        />

                                        {errors?.bed && (
                                          <span style={errorStyle}>
                                            {errors?.bed}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Capacity
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="capacity"
                                            value={formData.capacity}
                                            onChange={handleChange}
                                            placeholder="Capacity "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.capacity && (
                                          <span style={errorStyle}>
                                            {errors?.capacity}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      View
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="view"
                                            value={formData.view}
                                            onChange={handleChange}
                                            placeholder="view "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.view && (
                                          <span style={errorStyle}>
                                            {errors?.view}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Rating
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            as="select"
                                            name="rating"
                                            value={formData.rating}
                                            onChange={handleChange}
                                            onKeyPress={handleKeyPressContact}
                                            className="input-mandatory"
                                          >
                                            <option value="">
                                              Select Rating
                                            </option>
                                            {[1, 2, 3, 4, 5].map((value) => (
                                              <option key={value} value={value}>
                                                {value}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      price
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="price"
                                            value={formData.price}
                                            onChange={handleChange}
                                            placeholder="price "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.price && (
                                          <span style={errorStyle}>
                                            {errors?.price}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Facilities
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <Select
                                          options={facilityOptions}
                                          name="facility_id"
                                          isMulti
                                          value={facilities}
                                          onChange={handleFacilityChange}
                                        />
                                        {errors?.facilities && (
                                          <span style={errorStyle}>
                                            {errors?.facilities}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Image
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="image"
                                            accept="image/*"
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                [e.target.name]:
                                                  e.target.files[0],
                                              });
                                            }}
                                          />
                                        </InputGroup>
                                        {errors?.image && (
                                          <span style={errorStyle}>
                                            {errors?.image}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      images
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="images"
                                            accept="image/*"
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                [e.target.name]: e.target.files,
                                              });
                                            }}
                                            multiple
                                          />
                                          {errors?.images && (
                                            <span style={errorStyle}>
                                              {errors?.images}
                                            </span>
                                          )}
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div
                                  className="main-form-section mt-2"
                                  style={{ width: "100%", maxWidth: "800px" }}
                                >
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Description
                                    </Form.Label>
                                    <Col sm={9}>
                                      <Form.Group>
                                        <InputGroup>
                                          <JoditEditor
                                            ref={editor}
                                            value={formData.description}
                                            tabIndex={2}
                                            className="custom-jodit-editor"
                                            onChange={handleDescriptionChange}
                                          />
                                        </InputGroup>
                                        {errors?.description && (
                                          <span style={errorStyle}>
                                            {errors?.description}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
