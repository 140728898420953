import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import JoditEditor from 'jodit-react';
import Select from 'react-select';
import { CancelButton, SaveButton } from "../../../common/Button";
// import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
library.add(fas);
const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL } = useContext(Context);
  const [errors, setErrors] = useState();
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const id = props.show;
  const [formData, setFormData] = useState({
    name: "",
    title2: "",
    titleblur: "",
    subtitle: "",
    image: null,
    video: null,
    mediaType: "image",
  });

  const bannerTypeEnum = {
    Home: 'Home',
    Room: 'Room',
    Blog: 'Blog',
    'About Us': 'About Us',
    'Privacy Policy': 'Privacy Policy',
  };

  const [imagePreview, setImagePreview] = useState(null);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  // const handleChange = (e) => {
  //   if (e.target.name === "image") {
  //     setFormData({ ...formData, image: e.target.files[0] });

  //     // Generate image preview
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //     };
  //     reader.readAsDataURL(e.target.files[0]);
  //   } else {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   }
  // };



  const getMediaType = (fileType) => {
    return fileType.startsWith("image/") ? "image" : "video";
  };


  const handleChange = (e) => {
    // ... (other code)

    if (e.target.name === "image") {
      const selectedImage = e.target.files[0];

      setFormData({
        ...formData,
        [e.target.name]: selectedImage,
        mediaType: selectedImage ? getMediaType(selectedImage.type) : "image",
      });

      // Generate image preview
      if (selectedImage) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      } else {
        // Clear image preview
        setImagePreview(null);
      }
    } else {
      setFormData({ ...formData, [e.target?.name]: e.target.value });
    }
  };


  const validateForm = () => {
    let errors = {};
    const regexNumeric = /^[0-9]+$/;
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;


    if (!formData?.banner_type) {
      errors.banner_type = "Banner Type is required";
    }



    if (formData.banner_type === "Home") {
      if (!formData?.name) {
        errors.name = "Room name is required";
      } else if (formData.name.length > 20) {
        errors.name = "Name cannot exceed 20 characters";
      }
      if (!formData.title2) {
        errors.title2 = "Title is required";
      }
      // else if (formData.title2.length > 20) {
      //   errors.title2 = "Title cannot exceed 20 characters";
      // }
      if (!formData.titleblur) {
        errors.titleblur = "Title Blur is required";
      }
      else if (formData.titleblur.length > 20) {
        errors.titleblur = "Title cannot exceed 20 characters";
      }
      if (!formData.subtitle) {
        errors.subtitle = "Sub Title is required";
      }
      // else if (formData.subtitle.length > 30) {
      //   errors.subtitle = "Sub Title cannot exceed 30 characters";
      // }
    }
    if (!formData.mediaType) {
      errors.mediaType = "Media Type is required";
    }
    if (!formData.image) {
      errors.image = "Image or video is required";
    }
    // else if (formData.success && formData.success.code === 404) {
    //   errors.image = "Invalid image size. Width should be 1080 pixels and height should be 800 pixels";
    // }
    return errors;
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();

        formDataToSend.append("name", formData.name);
        formDataToSend.append("title2", formData.title2);
        formDataToSend.append("titleblur", formData.titleblur);
        formDataToSend.append("subtitle", formData.subtitle);
        formDataToSend.append("image", formData.image);
        formDataToSend.append("mediaType", formData.mediaType);
        formDataToSend.append("banner_type", formData.banner_type);

        const response = await postData(
          `/masters/banner/${id}`,
          formDataToSend
        );
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };
  const GetEditData = async () => {
    const response = await getData(`/masters/banner/${id}`);
    setFormData(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit banner</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container fluid>
            <Row>
              {/* Right side - Form */}
              <Col md={12}>
                <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                  <Card.Body>
                    <Card.Title>Edit Banner</Card.Title>
                    <hr />
                    <Container>
                      <Row>
                        {/* Editing fields */}
                        <Col md={8}>
                          <div className="main-form-section mt-2">
                            <Row className="justify-content-center">
                              <Form.Label column sm={3}>
                                Banner Type
                              </Form.Label>
                              <Col sm={6}>
                                <Form.Group>
                                  <Select
                                    options={Object.values(bannerTypeEnum).map(value => ({ label: value, value }))}
                                    value={{ label: formData.banner_type, value: formData.banner_type }}
                                    onChange={(selectedType) => setFormData({ ...formData, banner_type: selectedType.value || '' })}
                                  />
                                  {errors?.banner_type && (
                                    <span style={errorStyle}>{errors?.banner_type}</span>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>

                          {formData.banner_type === 'Home' && (
                            <div className="main-form-section mt-3">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Title
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData?.name}
                                        onChange={handleChange}
                                        placeholder="Title "
                                        id="inputEmail3"
                                        className="input-mandatory"
                                      />
                                    </InputGroup>
                                    {errors?.name && (
                                      <span style={errorStyle}>{errors?.name}</span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          )}


                          {formData.banner_type === 'Home' && (
                            <div className="main-form-section mt-3">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Title2
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        name="title2"
                                        value={formData?.title2}
                                        onChange={handleChange}
                                        placeholder="Title2 "
                                        id="inputEmail3"
                                        className="input-mandatory"
                                      />
                                    </InputGroup>
                                    {errors?.title2 && (
                                      <span style={errorStyle}>{errors.title2}</span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          )}

                          {formData.banner_type === 'Home' && (
                            <div className="main-form-section mt-3">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Title Blur
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        name="titleblur"
                                        value={formData.titleblur}
                                        onChange={handleChange}
                                        placeholder="Title Blur "
                                        id="inputEmail3"
                                        className="input-mandatory"
                                      />
                                    </InputGroup>
                                    {errors?.titleblur && (
                                      <span style={errorStyle}>{errors.titleblur}</span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          )}

                          {formData.banner_type === 'Home' && (
                            <div className="main-form-section mt-3">
                              <Row className="justify-content-center">
                                <Form.Label column sm={3}>
                                  Sub Title
                                </Form.Label>
                                <Col sm={6}>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        type="text"
                                        name="subtitle"
                                        value={formData.subtitle}
                                        onChange={handleChange}
                                        placeholder="Sub Title"
                                        id="inputEmail3"
                                        className="input-mandatory"
                                      />
                                    </InputGroup>
                                    {errors?.subtitle && (
                                      <span style={errorStyle}>{errors.subtitle}</span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          )}


                          {/* New field for uploading an image */}
                          <Row className="justify-content-center mt-3">
                            <Form.Label column sm={3}>
                              Image/Video
                            </Form.Label>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Control
                                  type="file"
                                  name="image"
                                  accept="image/*, video/*"
                                  onChange={handleChange}
                                />
                                {errors?.image && (
                                  <span style={errorStyle}>{errors?.image}</span>
                                )}
                              </Form.Group>
                            </Col>
                            {/* <Col sm={3}>
                              <Form.Group>
                                <Form.Select
                                  name="mediaType"
                                  onChange={handleChange}
                                  value={formData.mediaType}
                                >
                                  <option value="image">Image</option>
                                  <option value="video">Video</option>
                                </Form.Select>
                                {errors?.mediaType && (
                                  <span style={errorStyle}>{errors?.mediaType}</span>
                                )}
                              </Form.Group>
                            </Col> */}

                            {/* Left side - Image or Video */}
                            <Col md={4}>
                              {formData.mediaType === "image" && formData.image && (
                                <img
                                  src={imagePreview || IMG_URL + formData.image}
                                  alt="Image Preview"
                                  className="table-image"
                                  style={{
                                    height: "100px",
                                    width: "150px",
                                    margin: "4px",
                                    backgroundSize: "cover", // Fixed background size for image
                                  }}
                                />
                              )}

                              {formData.mediaType === "video" && formData.image && (
                                <video
                                  controls
                                  className="table-video"
                                  style={{
                                    height: "75px",
                                    width: "110px",
                                  }}
                                >
                                  <source src={imagePreview || IMG_URL + formData.image} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </Col>
                          </Row>



                          <Row className="mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link>
                                <CancelButton
                                  name={"cancel"}
                                  handleClose={props.handleClose}
                                />
                              </Link>
                              <SaveButton
                                name={"save"}
                                handleSubmit={handleSubmit}
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
