import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";


import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../../common/Button";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
const axios = require("axios");
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, deleteData, IMG_URL } = useContext(Context);

  const id = props.show;
  //const [category, setCategory] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    subname: "",
    image: null,
  });
  const [galleryImages, setGalleryImages] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.name == "gallery_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name === "gallery_id") {
      fetchDataForGallery(e.value);
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const fetchDataForGallery = async (gallery_id) => {
    try {
      const response = await getData(`/getgalleryimage/${gallery_id}`);

      if (response.success) {
        setGalleryImages(response.data.slice(0, 9)); // Display only the first 9 images
      }
    } catch (error) {
      console.error("Error fetching category images:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = await Validation.FormValidation({
      classname: ".galleryclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".galleryclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        const finalData = new FormData();
        finalData.append("name", formData.name);

        finalData.append("subname", formData.subname);

        if (formData.image) {
          for (let i = 0; i < formData.image.length; i++) {
            finalData.append(`image[${i}]`, formData.image[i]);
          }
        }
        const response = await postData(`/masters/gallery/${id}`, finalData);
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    try {
      const response = await getData(`/masters/gallery/${id}`);
      setDeleteImages(true);
      setFormData(response?.data);

      // Fetch subCategoryImages
      if (response?.data?.id) {
        fetchDataForGallery(response.data.id);
      }
    } catch (error) {
      console.error("Error fetching gallery data:", error);
    }
  };

  const handleDeleteImage = async (imageId) => {
    try {
      const response = await deleteData(`/masters/gallery/deletesgalleryimage/${imageId}`);

      if (response.data.success) {
        setGalleryImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
        GetEditData();
        // setSubCategoryImages(updatedImages);
        GetEditData();
      } else {
        // Handle error response
        console.error("Error deleting gallery image:", response.data.error);
      }
    } catch (error) {
      console.error("Error deleting gallery image:", error.message);
    }
  };
  useEffect(() => {
    //GetEditData(); 
  }, [galleryImages]);

  const renderSelectedGalleryImages = () => (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Image</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {galleryImages.map((i) => (
          <tr key={i.id}>
            <td>{i.id}</td>
            <td>
              <img
                src={IMG_URL + i.image}
                alt={`gallery-image-${i.id}`}
                className="gallery-image"
                style={{ height: '75px', width: '110px' }}
              />
            </td>
            <td>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleDeleteImage(i.id)}
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Gallery Data</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="galleryclass"
                          >
                            <Row>
                              <Col md={6}>
                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={5}>
                                      Gallery Images
                                    </Form.Label>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      <div className="selected-category-images">
                                        {renderSelectedGalleryImages()}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Name
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="name "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Subname
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="subname"
                                            value={formData.subname}
                                            onChange={handleChange}
                                            placeholder="subname"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      images
                                    </Form.Label>

                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="image"
                                            accept="image/*"
                                            onChange={(e) => {
                                              // setFormData({ ...formData, [e.target.name]: e.target.files });
                                              setFormData({ ...formData, image: e.target.files });
                                            }}
                                            multiple
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                                
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
