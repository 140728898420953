import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import JoditEditor from "jodit-react";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { postData } = useContext(Context);
  const [errors, setErrors] = useState();
  const [formData, setFormData] = useState({
    name: "",
    // subname: "",
    description: "",
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        // formDataToSend.append("subname", formData.subname);
        formDataToSend.append("description", formData.description);

        const response = await postData(
          "/masters/privacy-policy",
          formDataToSend
        );
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        await setErrors();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Hostel is required";
    }

    // if (!formData.subname) {
    //   errors.subname = "Policy Name is required";
    // }

    if (!formData.description) {
      errors.description = "Description is required";
    }

    return errors;
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Add privacy Policy</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="privacypolicyclass"
                          >
                            <Row>
                              <Row>
                                <Col md={9}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={2}>
                                        Title
                                      </Form.Label>
                                      <Col sm={9}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="name"
                                              value={formData.name}
                                              onChange={handleChange}
                                              placeholder="Title "
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          {errors?.name && (
                                            <span style={errorStyle}>
                                              {errors?.name}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                {/* <Col md={6}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        SubName
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="subname"
                                              value={formData.subname}
                                              onChange={handleChange}
                                              placeholder="SubName "
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          {errors?.subname && (
                                            <span style={errorStyle}>{errors?.subname}</span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col> */}

                                <Col md={9}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={2}>
                                        Description
                                      </Form.Label>
                                      <Col sm={9}>
                                        <Form.Group>
                                          <InputGroup>
                                            <JoditEditor
                                              ref={editor}
                                              value={formData.description}
                                              tabIndex={0}
                                              className="custom-jodit-editor"
                                              onBlur={(newContent) =>
                                                setContent(newContent)
                                              }
                                              onChange={handleDescriptionChange}
                                            />
                                          </InputGroup>
                                          {errors?.description && (
                                            <span style={errorStyle}>
                                              {errors?.description}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                              <Row></Row>
                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
