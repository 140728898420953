import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import toast, { Toaster } from "react-hot-toast";

import ModalDelete from "../../../common/ModalDelete";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";

library.add(fas);

// *****************************************************************************************************

const Tables = () => {
  const { getData, isAllow, deleteData, ErrorNotify, Per_Page_Dropdown } =
    useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [roomName, setRoomName] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/customermanegment/registration?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [stateName, setStateName] = useState([]);
  const [cityName, setCityName] = useState([]);
  const [countryName, setCountryName] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleView = (data) => {
    setSelectedData(data);
    setShowModal(true);
    getState(data?.state);
    getCity(data?.city);
    getCountry(data?.country);
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/customermanegment/registration/${recordToDeleteId}`
      );

      ErrorNotify(recordToDeleteName);
      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  async function getCountry(id) {
    const response = await getData(`/masters/country/${id}`);

    if (response?.success) {
      setCountryName(response.data?.name);
    }
  }

  async function getState(id) {
    const response = await getData(`/masters/state/${id}`);

    if (response?.success) {
      setStateName(response.data?.name);
    }
  }

  async function getCity(id) {
    const response = await getData(`/masters/city/${id}`);

    if (response?.success) {
      setCityName(response.data?.name);
    }
  }

  async function getRoom(id) {
    const response = await getData(`/masters/rooms/${id}`);

    if (response?.success) {
      setRoomName(response.data?.name);
    }
  }

  useEffect(() => {
    getRoom(FormData.roomId);
  }, [changeStatus, perPage, reset, show, show1]);

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col7: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header
          title={"Registration"}
          link={"/customermanegment/registration"}
        />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-5">
              <div className="">
                <div className="row">
                  <div className="d-flex">
                    <div className="add">
                      <div className="dropdown">
                        <button
                          className="btn btn-columns dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={colunms} className="columns " alt="" />
                          Column Selection{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button>

                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col1")}
                              href="#"
                            >
                              Sr. No.
                              {visible?.col1 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col2")}
                              href="#"
                            >
                              First Name
                              {visible?.col2 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col3")}
                              href="#"
                            >
                              Last Name
                              {visible?.col3 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col4")}
                              href="#"
                            >
                              Mobile No
                              {visible?.col4 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col5")}
                              href="#"
                            >
                              Email
                              {visible?.col5 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col6")}
                              href="#"
                            >
                              Arriving Date
                              {visible?.col6 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col7")}
                              href="#"
                            >
                              View
                              {visible?.col7 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-4">
                    <div className="  col-lg-4 col-md-4 col-12">
                      <div className="d-flex">
                        <div className="show me-2">
                          <p className="show mt-1">Show</p>
                        </div>
                        <div className="number me-2">
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            onChange={(e) => setperPage(e.target.value)}
                          >
                            {option?.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="entries">
                          <p className="show mt-1">entries</p>
                        </div>
                      </div>
                    </div>
                    <div className="  col-lg-8  col-md-8 col-12">
                      <div className="d-flex justify-content-end">
                        <div className="sowing me-2">
                          <p className="show mt-2">{`Showing ${Math.min(
                            (currentPage - 1) * perPage + 1
                          )} to ${Math.min(
                            currentPage * perPage,
                            data?.data?.totalEntries
                          )} of ${data?.data?.totalEntries} entries`}</p>
                        </div>
                        <div className="num me-2">
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                        <div className="Search me-2">
                          <button
                            type="button"
                            onClick={getDataAll}
                            className="btn btn-search"
                          >
                            <img src={search1} className="search" alt="" />
                          </button>
                        </div>

                        <div className="Search-1">
                          <button
                            type="button"
                            onClick={() => {
                              setSearch("");
                              setReset(!reset);
                            }}
                            className="btn btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-line mt-3"></div>
                <div className="row mt-3">
                  <div className="data table-responsive">
                    <Table striped bordered hover responsive center>
                      <thead>
                        <tr className="">
                          {visible.col1 && (
                            <th className="sr" style={{ width: "70px" }}>
                              Sr. No.
                            </th>
                          )}
                          {visible.col2 && (
                            <th className="tax-name" style={{ width: "250px" }}>
                              First Name
                            </th>
                          )}
                          {visible.col3 && (
                            <th className="tax-name" style={{ width: "250px" }}>
                              Last Name
                            </th>
                          )}
                          {visible.col4 && (
                            <th className="active" style={{ width: "200px" }}>
                              Mobile No
                            </th>
                          )}
                          {visible.col5 && (
                            <th className="active" style={{ width: "250px" }}>
                              Email
                            </th>
                          )}
                          {/*  {visible.col6 && <th className="active">Arriving Date</th>}
                          {visible.col7 && <th className="active">Arriving Time</th>}
                          {visible.col8 && <th className="active">Departing Date</th>}
                          {visible.col9 && <th className="active">Departing Time</th>}
                          {visible.col10 && <th className="active">No of Adults</th>}
                          {visible.col11 && <th className="active">Payment Method</th>}
                          {visible.col12 && <th className="active">Request</th>} */}
                          {visible.col7 && <th className="active">View</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.data?.map((d, index) => {
                          return (
                            <tr className="" key={index}>
                              {visible.col1 && <td>{++index}.</td>}
                              {visible.col2 && <td>{d?.first_name}</td>}
                              {visible.col3 && <td>{d?.last_name}</td>}
                              {visible.col4 && <td>{d?.mobile_number}</td>}
                              {visible.col5 && <td>{d?.email}</td>}
                              {/* {visible.col6 && <td>{d?.arriving_Date}</td>}
                            {visible.col7 && <td>{d?.arriving_time}</td>}
                                {visible.col8 && <td>{d?.departing_Date}</td>}
                                {visible.col9 && <td>{d?.departing_time}</td>}
                                {visible.col10 && <td>{d?.no_of_adults}</td>}
                                {visible.col11 && <td>{d?.payment_method}</td>}
                                {visible.col12 && <td>{d?.request}</td>} */}
                              {visible.col7 && (
                                <td>
                                  <span
                                    className="icon-space"
                                    style={{ marginLeft: "20px" }}
                                  ></span>
                                  {
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      className="view-icon"
                                      onClick={() => handleView(d)}
                                    />
                                  }
                                  <span
                                    className="icon-space"
                                    style={{ marginLeft: "20px" }}
                                  ></span>

                                  {/* {
                                    <FontAwesomeIcon
                                      icon={"trash-alt"}
                                      className="delete-icon"
                                      onClick={() => showDeleteRecord(d?.id, d?.first_name)}
                                    />
                                  } */}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>View Registration Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {selectedData && (
                            <div>
                              <p>
                                <b>Name :</b> {selectedData.first_name}{" "}
                                {selectedData.last_name}
                              </p>
                              <p>
                                <b>Mobile No. :</b> {selectedData.mobile_number}
                              </p>
                              <p>
                                <b>email :</b> {selectedData.email}
                              </p>
                              {/* <p>
                                <b>Arriving :</b> {selectedData.arriving_Date} -{" "}
                                {selectedData.arriving_time} -{" "}
                                {selectedData.arriving_time_marker}
                              </p>
                              <p>
                                <b>Departing :</b> {selectedData.departing_Date}{" "}
                                - {selectedData.departing_time} -{" "}
                                {selectedData.departing_time_marker}
                              </p> */}
                              {selectedData.roomName && (
                                <p>
                                  <b>Room Type :</b>
                                  {selectedData.roomName}
                                </p>
                              )}
                              <p>
                                <b>No. of Adults :</b>{" "}
                                {selectedData.no_of_adults}
                              </p>
                              <p>
                                <b>Country :</b> {countryName}{" "}
                              </p>
                              <p>
                                <b>State :</b> {stateName}{" "}
                              </p>
                              <p>
                                <b>City :</b> {cityName}{" "}
                              </p>
                              <p>
                                <b>Pin :</b> {selectedData.pincode}{" "}
                              </p>
                              <p>
                                <b>Address :</b> {selectedData.street}{" "}
                              </p>
                              <p>
                                <b>Payment Method :</b>{" "}
                                {selectedData.payment_method}
                              </p>
                              <p>
                                <b>Special Request : </b>
                                {selectedData.request}{" "}
                              </p>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setShowModal(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
