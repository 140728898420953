import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Admin/Login/Login";

import Dashboard from "./Components/Admin/DashBoard/DashBoard";
import Sidebar from "./Components/Admin/Sidebar/Sidebar";
import Header from "./Components/Admin/Header/Header";
import AdvanceDashboard from "./Components/Admin/DashBoard/AdvanceDashboard/AdvanceDashboard";
import PrivateDashboard from "./Components/Admin/DashBoard/PrivateDashboard/PrivateDashboard";
import Order from "./Components/Admin/DashBoard/Order/Order";

// Masters

// import PrimarySkills from "./Components/Admin/Masters/PrimarySkills/Tables";
// import SecondarySkills from "./Components/Admin/Masters/SecondarySkills/Tables";

// Talent Pool
//Customer management

import Contact from "./Components/Admin/CustomerManagement/Contact/Tables";

import Registration from "./Components/Admin/CustomerManagement/Registration/Tables";
// Recruiters

import Country from "./Components/Admin/Masters/Country/Tables";
import State from "./Components/Admin/Masters/State/Tables";
import City from "./Components/Admin/Masters/City/Tables";
import Banner from "./Components/Admin/Masters/Banner/Tables";
import Facilities from "./Components/Admin/Masters/Facilities/Tables";
import BlogFeed from "./Components/Admin/Masters/BlogFeed/Tables";
import Blog from "./Components/Admin/Masters/Blogs/Tables";
import Other from "./Components/Admin/Masters/OtherSay/Tables";
import Team from "./Components/Admin/Masters/Team/Tables";
import Bed from "./Components/Admin/Masters/Bed/Tables";
import Room from "./Components/Admin/Masters/Room/Tables";

import Gallery from "./Components/Admin/Masters/Gallery/Tables";
import OurRoomType from "./Components/Admin/Masters/OurRoomType/Tables";
import AboutVerified from "./Components/Admin/Masters/AboutVerified/Tables";
import LuxurySection from "./Components/Admin/Masters/LuxurySection/Tables";
import PrivacyPolicy from "./Components/Admin/Masters/PrivacyPolicy/Tables";
import AboutRoom from "./Components/Admin/Masters/AboutRoom/Tables";
import Videos from "./Components/Admin/Masters/Videos/Tables";
import SocialMedia from "./Components/Admin/Masters/SocialMedia/Table";

const App = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <div className="main d-flex">
      {headerText === "/" || headerText === "/lead-dashboard" ? (
        <></>
      ) : (
        <Sidebar />
      )}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Header" element={<Header />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/advanceDashboard" element={<AdvanceDashboard />} />

        {/* costomer Management Start */}

        {/* QueryInquiry Start */}

        {/* Contact us Start */}
        <Route path="/customermanegment/contact" element={<Contact />} />

        {/* Masters Start */}

        {/*Country */}
        <Route path="/masters/country" element={<Country />} />

        {/*State */}
        <Route path="/masters/state" element={<State />} />

        {/*City */}
        <Route path="/masters/city" element={<City />} />

        {/*banner */}
        <Route path="/masters/banner" element={<Banner />} />

        {/*Facilities */}
        <Route path="/masters/facilities" element={<Facilities />} />

        {/*Blog Feed */}
        <Route path="/masters/blogfeed" element={<BlogFeed />} />
        {/*blog */}
        <Route path="/masters/blogs" element={<Blog />} />

        {/*other-say */}
        <Route path="/masters/other-say" element={<Other />} />

        {/*other-say */}
        <Route path="/masters/team" element={<Team />} />

        {/*registraion */}
        <Route
          path="/customermanegment/registration"
          element={<Registration />}
        />

        {/*bed */}
        <Route path="/masters/bed" element={<Bed />} />

        {/*Room */}
        <Route path="/masters/room" element={<Room />} />

        {/*gallery */}
        <Route path="/masters/gallery" element={<Gallery />} />

        {/*gallery */}
        <Route path="/masters/our-room-type" element={<OurRoomType />} />

        {/*gallery */}
        <Route path="/masters/gallery" element={<Gallery />} />

        {/*gallery */}
        <Route path="/masters/our-room-type" element={<OurRoomType />} />

        {/*about-verified */}
        <Route path="/masters/about-verified" element={<AboutVerified />} />

        {/*luxury-section */}
        <Route path="/masters/luxury-section" element={<LuxurySection />} />

        {/*privacy policy */}
        <Route path="/masters/privacy-policy" element={<PrivacyPolicy />} />

        {/*privacy policy */}
        <Route path="/masters/about-room" element={<AboutRoom />} />

        {/*privacy policy */}
        <Route path="/masters/videos" element={<Videos />} />

        <Route path="/masters/social_media" element={<SocialMedia />} />
      </Routes>
    </div>
  );
};

export default App;

// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
