import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../../utils/context";

import hostimage from "../assets/icons/hostel-logo.png";
library.add(fas);

const Sidebar = () => {
  const { isAllow } = useContext(Context);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState("0");

  const [activeAccordionItem, setActiveAccordionItem] = useState(null);
  const [activeInnerListItem, setActiveInnerListItem] = useState(null);

  const handleAccordionClick = (itemKey) => {
    setActiveAccordionItem(itemKey === activeAccordionItem ? null : itemKey);
  };

  const handleInnerListItemClick = (itemId) => {
    setActiveInnerListItem(itemId === activeInnerListItem ? null : itemId);
  };

  return (
    <>
      <section style={{ width: isOpen ? "250px" : "50px" }} className="sidebar">
        <div className="top_section">
          <h1
            style={{ display: isOpen ? "block" : "none" }}
            className="logo ms-2"
          >
            {/* HOSTEL */}
          </h1>
          {isOpen && (
            <Link
              to="/advanceDashboard"
              className="link-body-emphasis d-inline-flex text-decoration-none rounded"
            >
              <img src={hostimage} className="bg-img " alt="Bliss Hostel" />
            </Link>
          )}
          <div
            style={{ marginLeft: isOpen ? "30px" : "22px" }}
            className="bars"
          >
            <FaBars onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>

        <ul className="list-unstyled ps-0">
          <Accordion defaultActiveKey={active}>
            <div
              eventKey="1"
              className={`mb-1 ${activeAccordionItem === "1" ? "active" : ""}`}
              onClick={() => handleAccordionClick("1")}
            >
              <div className="main-dash">
                <div className="text-clr me-3">
                  <FontAwesomeIcon icon="fa-solid fa-house" />
                </div>
                <Link to="/advanceDashboard">
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    Dashboard
                  </p>
                </Link>
              </div>
            </div>

            {/* ****************************************************************Settings ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="2"
              className={`mb-1 ${activeAccordionItem === "2" ? "active" : ""}`}
              onClick={() => handleAccordionClick("2")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Settings
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/country")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("country")}
                  >
                    <Link
                      to="/masters/country"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Country
                      </p>
                    </Link>
                  </li>

                  {/* State */}

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/state")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("state")}
                  >
                    <Link
                      to="/masters/state"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        State
                      </p>
                    </Link>
                  </li>

                  {/* City */}

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/city")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("city")}
                  >
                    <Link
                      to="/masters/city"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        City
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/banner")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("banner")}
                  >
                    <Link
                      to="/masters/banner"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Banner
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/luxury-section")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("luxury-section")}
                  >
                    <Link
                      to="/masters/luxury-section"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Luxury Section
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/our-room-type")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("our-room-type")}
                  >
                    <Link
                      to="/masters/our-room-type"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Rooms And Suits
                      </p>
                    </Link>
                  </li>

                  {/* Facilities */}
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/facilities")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("facilities")}
                  >
                    <Link
                      to="/masters/facilities"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Our Facilities
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/other-say")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("other-say")}
                  >
                    <Link
                      to="/masters/other-say"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        What Other Say
                      </p>
                    </Link>
                  </li>

                  {/* Blog Feed */}
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/blogfeed")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("blogfeed")}
                  >
                    <Link
                      to="/masters/blogfeed"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Blog Feed
                      </p>
                    </Link>
                  </li>

                  {/* Videos */}
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/videos")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("videos")}
                  >
                    <Link
                      to="/masters/videos"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Videos
                      </p>
                    </Link>
                  </li>

                  {/* ***********************************************home section ends****************************************************************** */}

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/beds")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("beds")}
                  >
                    <Link
                      to="/masters/bed"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Beds
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/room")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("room")}
                  >
                    <Link
                      to="/masters/room"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Rooms
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/about-room")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("about-room")}
                  >
                    <Link
                      to="/masters/about-room"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        About Us Upper Section
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/about-verified")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("about-verified")}
                  >
                    <Link
                      to="/masters/about-verified"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        About Us Middle Part
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/team")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("team")}
                  >
                    <Link
                      to="/masters/team"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        About Us Team
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/gallery")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("gallery")}
                  >
                    <Link
                      to="/masters/gallery"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Gallery
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/privacy-policy")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("privacy-policy")}
                  >
                    <Link
                      to="/masters/privacy-policy"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Privacy Policy
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/blogs")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("blogs")}
                  >
                    <Link
                      to="/masters/blogs"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Blog
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/social_media")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("social_media")}
                  >
                    <Link
                      to="/masters/social_media"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Social media
                      </p>
                    </Link>
                  </li>
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>
            {/* ****************************************************************Customer Management ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="3"
              className={`mb-1 ${activeAccordionItem === "3" ? "active" : ""}`}
              onClick={() => handleAccordionClick("2")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Customer Management
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/customermanegment/contact")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("contact")}
                  >
                    <Link
                      to="/customermanegment/contact"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Contact
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/customermanegment/registration")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("registration")}
                  >
                    <Link
                      to="/customermanegment/registration"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Registration
                      </p>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ul>
      </section>
    </>
  );
};

export default Sidebar;
