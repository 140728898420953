import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import JoditEditor from "jodit-react";
import Select from "react-select";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { esAR } from "rsuite/esm/locales";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData } = useContext(Context);
  const [errors, setErrors] = useState();
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    title2: "",
    titleblur: "",
    subtitle: "",
    file: null,
    mediaType: "image",
    banner_type: "",
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const bannerTypeEnum = {
    Home: "Home",
    Room: "Room",
    Blog: "Blog",
    "About Us": "About Us",
    "Privacy Policy": "Privacy Policy",
  };

  const getMediaType = (fileType) => {
    // You might need to enhance this logic based on actual file types you receive
    return fileType.startsWith("image/") ? "image" : "video";
  };
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);

  const handleChange = (e) => {
    if (e.target.name === "image") {
      const file = e.target.files[0];
      setFormData({
        ...formData,
        [e.target.name]: file,
        mediaType: file ? getMediaType(file.type) : "image",
      });

      // Display video preview
      if (file && file.type.startsWith("video/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setVideoPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setVideoPreview(null);
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexNumeric = /^[0-9]+$/;
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData?.banner_type) {
      errors.banner_type = "Banner Type is required";
    }

    if (formData.banner_type === "Home") {
      if (!formData?.name) {
        errors.name = "Room name is required";
      } else if (formData.name.length > 20) {
        errors.name = "Name cannot exceed 20 characters";
      }
      if (!formData.title2) {
        errors.title2 = "Title is required";
      }
      // else if (formData.title2.length > 20) {
      //   errors.title2 = "Title cannot exceed 20 characters";
      // }
      if (!formData.titleblur) {
        errors.titleblur = "Title Blur is required";
      } else if (formData.titleblur.length > 20) {
        errors.titleblur = "Title cannot exceed 20 characters";
      }
      if (!formData.subtitle) {
        errors.subtitle = "Sub Title is required";
      }
      // else if (formData.subtitle.length > 30) {
      //   errors.subtitle = "Sub Title cannot exceed 30 characters";
      // }
    }
    if (!formData.mediaType) {
      errors.mediaType = "Media Type is required";
    }
    if (!formData.image) {
      errors.image = "Image or video is required";
    }
    // else if (formData.success && formData.success.code === 404) {
    //   errors.image = "Invalid image size. Width should be 1080 pixels and height should be 800 pixels";
    // }
    return errors;
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const [errorMesage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();

        formDataToSend.append("name", formData.name);
        formDataToSend.append("title2", formData.title2);
        formDataToSend.append("titleblur", formData.titleblur);
        formDataToSend.append("subtitle", formData.subtitle);
        formDataToSend.append("image", formData.image);
        formDataToSend.append("mediaType", formData.mediaType);
        formDataToSend.append("banner_type", formData.banner_type);

        const response = await postData("/masters/banner", formDataToSend);
        console.log(":---", response);
        if (response?.success) {
          setErrorMessage("....");
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else if (response?.code == 400) {
          setErrorMessage(response?.message);
        } else {
          setErrorMessage("..");
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        await setErrors();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Solution</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Add Banner</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="bannerclass"
                          >
                            <Row>
                              <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <div className="main-form-section mt-2">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Banner Type
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <Select
                                          options={Object.values(
                                            bannerTypeEnum
                                          ).map((value) => ({
                                            label: value,
                                            value,
                                          }))}
                                          value={{
                                            label: formData.banner_type,
                                            value: formData.banner_type,
                                          }}
                                          onChange={(selectedType) =>
                                            setFormData({
                                              ...formData,
                                              banner_type:
                                                selectedType.value || "",
                                            })
                                          }
                                        />
                                        {errors?.banner_type && (
                                          <span style={errorStyle}>
                                            {errors?.banner_type}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                                {formData.banner_type === "Home" && (
                                  <div className="main-form-section mt-3">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Title
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="name"
                                              value={formData.name}
                                              onChange={handleChange}
                                              placeholder="Title "
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          {errors?.name && (
                                            <span style={errorStyle}>
                                              {errors?.name}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                )}

                                {formData.banner_type === "Home" && (
                                  <div className="main-form-section mt-3">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Title2
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="title2"
                                              value={formData.title2}
                                              onChange={handleChange}
                                              placeholder="Title2 "
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          {errors?.title2 && (
                                            <span style={errorStyle}>
                                              {errors.title2}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                )}

                                {formData.banner_type === "Home" && (
                                  <div className="main-form-section mt-3">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Title Blur
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="titleblur"
                                              value={formData.titleblur}
                                              onChange={handleChange}
                                              placeholder="Title Blur "
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          {errors?.titleblur && (
                                            <span style={errorStyle}>
                                              {errors.titleblur}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                )}

                                {formData.banner_type === "Home" && (
                                  <div className="main-form-section mt-3">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={3}>
                                        Sub Title
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="subtitle"
                                              value={formData.subtitle}
                                              onChange={handleChange}
                                              placeholder="Sub Title"
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          {errors?.subtitle && (
                                            <span style={errorStyle}>
                                              {errors.subtitle}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                )}

                                <div
                                  className="main-form-section mt-3"
                                  style={{ width: "100%", maxWidth: "800px" }}
                                >
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      File (Image/Video)
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <Form.Control
                                          type="file"
                                          name="image"
                                          accept="image/*,video/*"
                                          onChange={handleChange}
                                        />
                                        {errors?.image && (
                                          <span style={errorStyle}>
                                            {errors?.image}
                                          </span>
                                        )}
                                        {errorMesage.length >= 1 && (
                                          <span style={errorStyle}>
                                            {errorMesage}
                                          </span>
                                        )}

                                        {imagePreview && !videoPreview && (
                                          <div className="mt-2">
                                            <img
                                              src={imagePreview}
                                              alt="Preview"
                                              style={{
                                                maxWidth: "100%",
                                                maxHeight: "150px",
                                              }}
                                            />
                                          </div>
                                        )}

                                        {videoPreview && (
                                          <div className="mt-2">
                                            <video
                                              controls
                                              width="100%"
                                              height="150"
                                              src={videoPreview}
                                            >
                                              Your browser does not support the
                                              video tag.
                                            </video>
                                          </div>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>

                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
