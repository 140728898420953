import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import JoditEditor from "jodit-react";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
// import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
library.add(fas);
const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL } = useContext(Context);
  const id = props.show;
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedImage = e.target.files[0];

      // Check if a file is selected
      if (selectedImage) {
        // Generate image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);

        // Update formData with the selected image
        setFormData({ ...formData, image: selectedImage });
      } else {
        // User canceled image selection, clear the image preview and formData
        setImagePreview(null);
        setFormData({ ...formData, image: null });
      }
    } else {
      // Handle text input
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("description", formData.description);
        formDataToSend.append("image", formData.image);

        const response = await postData(
          `/masters/other-say/${id}`,
          formDataToSend
        );
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        await setErrors();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Title is required";
    } else if (!regexAlfaNumric.test(formData.name?.trim())) {
      errors.name = "Enter a valid data";
    }

    if (!formData.image) {
      errors.image = "Image is required";
    }

    if (!formData.description) {
      errors.description = "description is required";
    } else if (formData.description.length > 180) {
      errors.description = "Description should be Maximum 180 characters ";
    }

    return errors;
  };
  const GetEditData = async () => {
    const response = await getData(`/masters/other-say/${id}`);
    setFormData(response?.data);
  };
  useEffect(() => {
    GetEditData();
  }, []);
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };
  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit banner</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container fluid>
            <Row>
              {/* Right side - Form */}
              <Col md={12}>
                <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                  <Card.Body>
                    <Card.Title>Edit What Other Say</Card.Title>
                    <hr />
                    <Container>
                      <Row className="justify-content-center">
                        {/* Editing fields */}
                        <Col md={8}>
                          <Row>
                            <Col md={12}>
                              <div className="main-form-section mt-2">
                                <Row className="justify-content-center">
                                  <Form.Label column sm={3}>
                                    Name
                                  </Form.Label>
                                  <Col sm={9}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="text"
                                          name="name"
                                          value={formData?.name}
                                          onChange={handleChange}
                                          placeholder="Name"
                                          className="input-mandatory"
                                          id="inputEmail3"
                                        />
                                      </InputGroup>
                                      {errors?.name && (
                                        <span style={errorStyle}>
                                          {errors?.name}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div className="main-form-section mt-2">
                                <Row className="justify-content-center">
                                  <Form.Label column sm={3}>
                                    Image
                                  </Form.Label>
                                  <Col sm={9}>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          type="file"
                                          name="image"
                                          accept="image/*"
                                          onChange={handleChange}
                                        />
                                      </InputGroup>
                                      {errors?.image && (
                                        <span style={errorStyle}>
                                          {errors?.image}
                                        </span>
                                      )}
                                    </Form.Group>

                                    {/* Left side - Image */}

                                    {imagePreview ? (
                                      <img
                                        src={imagePreview}
                                        alt="Image Preview"
                                        className="table-image"
                                        style={{
                                          height: "100px",
                                          width: "150px",
                                          margin: "4px",
                                          marginLeft: "150px",
                                          backgroundSize: "fixed",
                                        }}
                                      />
                                    ) : (
                                      formData?.image && (
                                        <img
                                          src={IMG_URL + formData.image}
                                          alt={formData?.image || "Image"}
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "150px",
                                            marginTop: "4px",
                                            backgroundSize: "fixed",
                                          }}
                                        />
                                      )
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <div className="main-form-section mt-2">
                                <Row className="justify-content-center">
                                  <Form.Label column sm={3}>
                                    Description
                                  </Form.Label>
                                  <Col sm={9}>
                                    <Form.Group>
                                      <InputGroup>
                                        <JoditEditor
                                          ref={editor}
                                          value={formData.description}
                                          tabIndex={2}
                                          className="custom-jodit-editor"
                                          onBlur={(newContent) =>
                                            setContent(newContent)
                                          }
                                          onChange={handleDescriptionChange}
                                        />
                                      </InputGroup>
                                      {errors?.description && (
                                        <span style={errorStyle}>
                                          {errors?.description}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link>
                                <CancelButton
                                  name={"cancel"}
                                  handleClose={props.handleClose}
                                />
                              </Link>
                              <SaveButton
                                name={"save"}
                                handleSubmit={handleSubmit}
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};
export default EditOffCanvance;
