import React, { useState, useContext } from "react";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import { Context } from "../../../../utils/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const LoginPage = () => {
  const { postData, signin, setSignin, usertype, setUsertype } =
    useContext(Context);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [showErrorModal, setShowErrorModal] = useState();
  const [Hide, setHide] = useState(false);

  const validateForm = () => {
    let errors = {};

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    if (!formData.password.trim()) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await postData("/login", formData);

        if (response.success) {
          setSignin(response.success);
          setUsertype(response?.data?.rolename);

          Cookies.set("pro_hostel_security", response.data?.token, {
            expires: 2,
          });
          // if (response?.data?.role === "admin") {
          //   navigate("/masters/country");
          // } else if ("tele-caller") {
          //   navigate("/lead-dashboard");
          // }
      

          if (response?.data?.role === "tele-caller") {
            navigate("/lead-dashboard");
          } else {
            navigate("/advanceDashboard");
          }
        } else {
          setShowErrorModal(response);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };
  return (
    <>
      <section className="Login">
        <div className="container-fluid p-0">
          <div className="card text-center login-card p-5">
            <div className="text-holder-login">
              <h2>Login Here</h2>

              {showErrorModal?.success ? (
                <></>
              ) : (
                <>
                  <span style={errorStyle}>{showErrorModal?.message}</span>
                </>
              )}
              <form onSubmit={handleSubmit} role="form">
                <div className="mb-3 loginForm_txt_main">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label loginForm_txt"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-email"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span style={errorStyle}>{errors.email}</span>
                  )}
                </div>
                <div className="loginForm_txt_main">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label loginForm_txt"
                  >
                    Password
                  </label>
                  <input
                    type={Hide ? "text" : "password"}
                    className="form-control form-control-email"
                    placeholder="Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <FontAwesomeIcon
                    icon={Hide ? faEye : faEyeSlash}
                    className="login_eye_icon"
                    onClick={() => setHide(!Hide)}
                  />

                  {/* <button
                    type="button"
                    className="input-group-text hide-name"
                    id="basic-addon2"
                    onClick={() => {
                      setHide(!Hide);
                    }}
                  >
                    {Hide ? "Hide" : "Show"}
                  </button> */}
                </div>
                {errors.password && (
                  <span style={errorStyle}>{errors.password}</span>
                )}

                <Link to="/">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn sign-in-button text-center"
                  >
                    Login
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
