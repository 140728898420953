import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import Select from 'react-select';
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";
import JoditEditor from "jodit-react"
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, IMG_URL } = useContext(Context);
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [errors, setErrors] = useState();
  const [videoPreview, setVideoPreview] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    video: null,
    video_type: "",
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const typeEnum = {
    Home: 'Home',
    'About Us': 'About Us',
  };

  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedVideo = e.target.files[0];

      // Check if a file is selected
      if (selectedVideo) {
        // Generate image preview and update formData based on the input name
        const reader = new FileReader();
        reader.onloadend = () => {
          if (e.target.name === "video") {
            setVideoPreview(reader.result);
            setFormData({ ...formData, video: selectedVideo });
          }
        };
        reader.readAsDataURL(selectedVideo);
      } else {
        // User canceled image selection, clear the image preview and formData
        if (e.target.name === "video") {
          setVideoPreview(null);
          setFormData({ ...formData, video: null });
        }
      }
    } else {
      // Handle text input
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };



  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {

        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("description", formData.description);
        formDataToSend.append("video", formData.video);
        formDataToSend.append("video_type", formData.video_type);


        const response = await postData("/masters/videos", formDataToSend);
        if (response?.success) {
          setShowModal({ code: response?.code, message: response?.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response?.code, message: response?.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        await setErrors();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "Title is required";
    } else if (!regexAlfaNumric.test(formData.name?.trim())) {
      errors.name = "Enter a valid data";
    };

    if (!formData.video) {
      errors.video = "Video is required";
    }

    if (!formData.video_type) {
      errors.video_type = "Video Type is required";
    }

    if (!formData.description) {
      errors.description = "description is required";
    }


    return errors;
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  }

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Solution</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded ">
            <Card.Body>
              <Card.Title>Add Videos</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="videoclass"
                          >
                            <Row>
                              <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-2">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Video Type
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <Select
                                              options={Object.values(typeEnum).map(value => ({ label: value, value }))}
                                              value={{ label: formData.video_type, value: formData.video_type }}
                                              onChange={(selectedType) => setFormData({ ...formData, video_type: selectedType.value || '' })}
                                            />
                                            {errors?.video_type && (
                                              <span style={errorStyle}>{errors?.video_type}</span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-2">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Name
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder="Name "
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.name && (
                                              <span style={errorStyle}>{errors?.name}</span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-2">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Sub Title
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="text"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                placeholder="Sub Title "
                                                id="inputEmail3"
                                                className="input-mandatory"
                                              />
                                            </InputGroup>
                                            {errors?.description && (
                                              <span style={errorStyle}>{errors?.description}</span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>
                                    <div className="main-form-section mt-2">
                                      <Row className="justify-content-center">
                                        <Form.Label column sm={3}>
                                          Video
                                        </Form.Label>
                                        <Col sm={6}>
                                          <Form.Group>
                                            <InputGroup>
                                              <Form.Control
                                                type="file"
                                                name="video"
                                                accept="video/*"
                                                onChange={handleChange}
                                              />
                                            </InputGroup>
                                            {errors?.video && (
                                              <span style={errorStyle}>{errors?.video}</span>
                                            )}
                                          </Form.Group>
                                        </Col>
                                        {/* Left side - Image */}
                                        <Col md={4}>
                                          {formData.video && (
                                            <video
                                              controls
                                              className="table-video"
                                              style={{
                                                height: "75px",
                                                width: "110px",
                                              }}
                                            >
                                              <source src={videoPreview || IMG_URL + formData.video} type="video/mp4" />
                                              Your browser does not support the video tag.
                                            </video>
                                          )}
                                        </Col>

                                      </Row>
                                    </div>
                                  </Col>
                                </Row>



                                <Row className="mt-5 pb-3">
                                  <div className="d-flex justify-content-center">
                                    <Link>
                                      <CancelButton
                                        name={"cancel"}
                                        handleClose={props.handleClose}
                                      />
                                    </Link>
                                    <SaveButton
                                      name={"save"}
                                      handleSubmit={handleSubmit}
                                    />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
